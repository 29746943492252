import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LIST_BAGGING_DISPATCH_BY_RANGE_QUERY } from '../libs/graphQL';
import { BaggingDispatch, ListBaggingDispatchByRangeQuery, ListBaggingDispatchByRangeQueryVariables } from '../libs/types';
import { MealViewFilters } from '../views/LeftoverMetrics/MealsView/MealsView';

const useListBaggingDispatchByRangeQuery = (filters: MealViewFilters, limit = 200) => {
  const [next, setNext] = useState<string | undefined>();
  const [baggingDispatches, setBaggingDispatches] = useState<BaggingDispatch[]>();

  const { data, fetchMore, loading } = useQuery<ListBaggingDispatchByRangeQuery, ListBaggingDispatchByRangeQueryVariables>(
    LIST_BAGGING_DISPATCH_BY_RANGE_QUERY,
    {
      variables: {
        kitchen: filters.kitchen,
        startDate: filters.startDate,
        endDate: filters.endDate,
        limit
      },
      onError(error) {
        toast.error(error.message);
      }
    }
  );

  useEffect(() => {
    if (data) {
      setNext(data.listBaggingDispatchByRange?.next);
      setBaggingDispatches(data.listBaggingDispatchByRange?.dispatches ?? []);
    }
  }, [data])

  function onFetchMore() {
    if (next) {
      fetchMore({
        variables: { next },
        updateQuery(previousQueryResult, { fetchMoreResult }) {
          const prevBaggingSessionsData = previousQueryResult.listBaggingDispatchByRange;
          const baggingSessionsData = fetchMoreResult.listBaggingDispatchByRange;

          return {
            listBaggingDispatchByRange: {
              ...previousQueryResult.listBaggingDispatchByRange,
              next: fetchMoreResult.listBaggingDispatchByRange?.next,
              dispatches: [...(prevBaggingSessionsData?.dispatches ?? []), ...(baggingSessionsData?.dispatches ?? [])]
            }
          };
        }
      });
    }
  }

  return {
    hasNext: Boolean(next),
    baggingDispatches,
    dispatchesLoading: loading,
    fetchMoreDispatches: onFetchMore
  };
};

export default useListBaggingDispatchByRangeQuery;
