import { useLazyQuery, useQuery } from '@apollo/client';
import { FoodType } from '@calo/types';
import { Backdrop, Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AppContext } from '../../../App';
import { caloTheme } from '../../../assets/themes/calo';
import EmptyMessage from '../../../components/EmptyMessage';
import useListBaggingDispatchByRangeQuery from '../../../hooks/useListBaggingDispatchByRangeQuery';
import {
	ExportBaggingSessionQuery,
	Kitchen,
	LeftoverMetricsViewTypes,
	ListDailyDispatchLeftoverStatsQuery,
	ListDailyDispatchLeftoverStatsQueryVariables,
	Session
} from '../../../libs';
import { EXPORT_BAGGING_SESSION_QUERY, LIST_DAILY_DISPATCH_LEFTOVER_STATS_QUERY } from '../../../libs/graphQL';
import { createAutoDownloadLinkFromFile, doesFoodMatchFilters, makeCustomMealRepresentative } from '../../../libs/utils';
import FiltersDrawer from '../Common/FiltersDrawer';
import FilterSection from '../Common/FilterSection';
import { getDefaultDates } from '../helpers';
import FilterItems from './FiltersItems';
import SectionPage from './SectionPage';
import TopSection from './TopSection';

interface MealsViewProps {
	kitchen: Kitchen;
	date: string;
	shift: Session;
	areas: string[];
	selectedView: LeftoverMetricsViewTypes;
	setSelectedView: React.Dispatch<React.SetStateAction<LeftoverMetricsViewTypes>>;
}

export interface DailyDispatchLeftoverStats {
	totalLeftovers: number;
	costOfLeftovers: number;
	foodCostOfLeftovers: number;
	packagingCostOfLeftovers: number;
}

export interface MealViewFilters {
	kitchen: Kitchen;
	startDate: string;
	endDate: string;
	shift: string;
	cities: string[];
	mealTypes: FoodType[];
	onlySandwiches: boolean;
	actions: string[];
}

const MealsView = ({ selectedView, setSelectedView, areas, shift, kitchen, date }: MealsViewProps) => {
	const limit = 50;
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [filters, setFilters] = useState<MealViewFilters>(getDefaultFilters());

	const theme = useTheme();
	const appContext = useContext(AppContext);

	function getDefaultFilters() {
		return {
			kitchen,
			...getDefaultDates(date),
			cities: [],
			mealTypes: [],
			onlySandwiches: false,
			shift: shift,
			actions: []
		};
	}
	const [getDocument, { loading: documentLoading }] = useLazyQuery<ExportBaggingSessionQuery>(EXPORT_BAGGING_SESSION_QUERY, {
		variables: {
			shift: filters.shift,
			kitchen: filters.kitchen,
			startDate: filters.startDate,
			endDate: filters.endDate,
			city: filters.cities,
			mealType: filters.mealTypes,
			onlySandwich: filters.onlySandwiches,
			actions: [],
			fileType: 'CSV'
		},
		fetchPolicy: 'network-only'
	});

	const handleDownload = async () => {
		const { data } = await getDocument();

		if (data?.exportBaggingSession && data?.exportBaggingSession.data) {
			createAutoDownloadLinkFromFile(
				data.exportBaggingSession.data,
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'xlsx',
				`Calo Dispatch Record ${kitchen}-${filters.shift}-${date}`
			);
		}
	};

	const { data: dailyDispatchLeftoverStasResponse } = useQuery<
		ListDailyDispatchLeftoverStatsQuery,
		ListDailyDispatchLeftoverStatsQueryVariables
	>(LIST_DAILY_DISPATCH_LEFTOVER_STATS_QUERY, {
		variables: {
			kitchen: filters.kitchen,
			startDate: filters.startDate,
			endDate: filters.endDate
		}
	});

	const { baggingDispatches, hasNext, fetchMoreDispatches, dispatchesLoading } = useListBaggingDispatchByRangeQuery(
		filters,
		limit
	);

	useEffect(() => {
		setFilters({ ...filters, kitchen, ...getDefaultDates(date) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kitchen, date]);

	const dispatches = useMemo(() => {
		if (!baggingDispatches) return [];

		const custom: typeof baggingDispatches = [];
		const nonCustom: typeof baggingDispatches = [];

		baggingDispatches.map((dispatchedMeal) => {
			if (doesFoodMatchFilters(dispatchedMeal, filters)) {
				if (dispatchedMeal.isCustomFood) custom.push(dispatchedMeal);
				else nonCustom.push(dispatchedMeal);
			}
		});

		return custom.length ? [...nonCustom, makeCustomMealRepresentative(custom, filters.cities)] : nonCustom;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baggingDispatches, filters.shift, filters.cities, filters.mealTypes, filters.onlySandwiches]);

	const dailyDispatchLeftoverStats = useMemo(() => {
		const defaultStats = {
			totalLeftovers: 0,
			costOfLeftovers: 0,
			foodCostOfLeftovers: 0,
			packagingCostOfLeftovers: 0
		};

		return (
			dailyDispatchLeftoverStasResponse?.listDailyDispatchLeftoverStats?.data?.reduce((acc, { stats }) => {
				for (const stat of stats ?? []) {
					if (!filters.mealTypes.length || filters.mealTypes.includes(stat.mealType as FoodType)) {
						const key = filters.onlySandwiches ? 'sandwiches' : 'nonSandwiches';
						const { foodCost = 0, packagingCost = 0, count = 0 } = stat[key];

						acc.costOfLeftovers += count * (foodCost + packagingCost);
						acc.foodCostOfLeftovers += count * foodCost;
						acc.packagingCostOfLeftovers += count * packagingCost;
						acc.totalLeftovers += count;
					}
				}
				return acc;
			}, defaultStats) ?? defaultStats
		);
	}, [dailyDispatchLeftoverStasResponse, filters.mealTypes, filters.onlySandwiches]);

	useEffect(() => {
		if (dispatches.length < limit && hasNext) {
			fetchMoreDispatches();
		}
	}, [dispatches]);

	return (
		<InfiniteScroll
			hasMore={hasNext}
			next={fetchMoreDispatches}
			dataLength={dispatches.length}
			loader={
				hasNext ? (
					<Stack direction="column" alignItems="center">
						<CircularProgress sx={{ textAlign: 'center', m: 2 }} />
					</Stack>
				) : null
			}
		>
			<Box sx={{ width: '100%' }}>
				<TopSection dailyDispatchLeftoverStats={dailyDispatchLeftoverStats} />
				<FilterSection
					selectedView={selectedView}
					setSelectedView={setSelectedView}
					handleDownload={handleDownload}
					isLoading={documentLoading}
					openFilterDrawer={setIsFilterOpened}
					isFilterActive={false}
				/>
				<FiltersDrawer isFilterOpened={isFilterOpened} setIsFilterOpened={setIsFilterOpened}>
					<FilterItems
						areas={areas}
						filters={filters}
						setFilters={setFilters}
						getDefaultFilters={getDefaultFilters}
						toggleFilersDrawer={(bool: boolean) => setIsFilterOpened(bool)}
					/>
				</FiltersDrawer>
				<Stack direction="column" sx={{ mt: '25px', mb: '10px', width: '100%' }}>
					{appContext.isOffline || ((!baggingDispatches || !baggingDispatches.length) && !hasNext && !dispatchesLoading) ? (
						<EmptyMessage label={'no data'} style={{ margin: 0 }} />
					) : dispatchesLoading ? (
						<Box
							sx={{
								fontFamily: caloTheme.typography.fontFamily,
								fontSize: '28px',
								textTransform: 'uppercase',
								color: theme.palette.neutral600,
								mt: '55px'
							}}
						>
							Loading ...
						</Box>
					) : (
						<SectionPage dispatchedMeals={dispatches} filters={filters} />
					)}
				</Stack>
				<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={dispatchesLoading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Box>
		</InfiniteScroll>
	);
};

export default MealsView;
