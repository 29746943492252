
    import { gql } from '@apollo/client';
    
      export const LOCALIZED_FIELDS = gql`
        
        fragment LocalizedFields on Localized {
          ar
  en
        }
      `;
    

      export const PAUSED_INTERVAL_FIELDS = gql`
        
        fragment PausedIntervalFields on PausedInterval {
          pausedAt
  resumedAt
        }
      `;
    

      export const PROCESS_TIME_FIELDS = gql`
        ${PAUSED_INTERVAL_FIELDS}
        fragment ProcessTimeFields on ProcessTime {
          startedAt
  intervals {
          ...PausedIntervalFields
        }
        }
      `;
    

      export const COOKING_STATIONS_FIELDS = gql`
        ${PROCESS_TIME_FIELDS}
        fragment CookingStationsFields on CookingStations {
          name
  status
  processTime {
          ...ProcessTimeFields
        }
  endTime
        }
      `;
    

      export const KITCHEN_WASTAGE_INPUTS_FIELDS = gql`
        
        fragment KitchenWastageInputsFields on KitchenWastageInputs {
          preppedWeight
  chilledWeight
  wastage
  action
        }
      `;
    

      export const FC_INGREDIENT_FIELDS = gql`
        
        fragment fcIngredientFields on fcIngredient {
          id
  quantity
        }
      `;
    

      export const FOOD_COMPONENT_METHOD_STEP_FIELDS = gql`
        
        fragment FoodComponentMethodStepFields on FoodComponentMethodStep {
          description
  attachment
  station
        }
      `;
    

      export const LINKED_COMPONENT_FIELDS = gql`
        
        fragment LinkedComponentFields on LinkedComponent {
          id
  quantity
        }
      `;
    

      export const CUP_SIZES_FIELDS = gql`
        
        fragment CupSizesFields on CupSizes {
          size
  value
        }
      `;
    

      export const CUPS_QUANTITY_FIELDS = gql`
        
        fragment CupsQuantityFields on CupsQuantity {
          cup
  quantity
        }
      `;
    

      export const COMPONENT_SHORTAGE_FIELDS = gql`
        ${LOCALIZED_FIELDS}
${COOKING_STATIONS_FIELDS}
        fragment ComponentShortageFields on ComponentShortage {
          id
  foodId
  foodName {
          ...LocalizedFields
        }
  foodSize
  weight
  cookingStations {
          ...CookingStationsFields
        }
  createdAt
        }
      `;
    

      export const CHILD_FIELDS = gql`
        
        fragment ChildFields on Child {
          count
  weight
        }
      `;
    

      export const FOOD_COMPONENT_QUALITY_STANDARD_FIELDS = gql`
        
        fragment FoodComponentQualityStandardFields on FoodComponentQualityStandard {
          id
  name
  description
  imageURLs
        }
      `;
    

      export const USER_FIELDS = gql`
        
        fragment UserFields on User {
          id
  name
  email
        }
      `;
    

      export const FOOD_COMPONENT_FIELDS = gql`
        ${LOCALIZED_FIELDS}
${COOKING_STATIONS_FIELDS}
${KITCHEN_WASTAGE_INPUTS_FIELDS}
${FC_INGREDIENT_FIELDS}
${FOOD_COMPONENT_METHOD_STEP_FIELDS}
${LINKED_COMPONENT_FIELDS}
${CUP_SIZES_FIELDS}
${CUPS_QUANTITY_FIELDS}
${COMPONENT_SHORTAGE_FIELDS}
${CHILD_FIELDS}
${FOOD_COMPONENT_QUALITY_STANDARD_FIELDS}
${USER_FIELDS}
        fragment FoodComponentFields on FoodComponent {
          id
  tk
  name {
          ...LocalizedFields
        }
  count
  weight
  cookedWeight
  measurementUnit
  cookingStations {
          ...CookingStationsFields
        }
  date
  country
  kitchen
  kitchenWastageInputs {
          ...KitchenWastageInputsFields
        }
  brand
  ingredients {
          ...fcIngredientFields
        }
  method {
          ...FoodComponentMethodStepFields
        }
  childComponents {
          ...LinkedComponentFields
        }
  foodsIds
  cost
  quantities
  quantity
  cups {
          ...CupSizesFields
        }
  cupsQuantity {
          ...CupsQuantityFields
        }
  shortages {
          ...ComponentShortageFields
        }
  purchasingCost
  child {
          ...ChildFields
        }
  standards {
          ...FoodComponentQualityStandardFields
        }
  isVerified
  verifiedBy {
          ...UserFields
        }
        }
      `;
    

      export const LIST_FOOD_COMPONENTS_RESPONSE_FIELDS = gql`
        ${FOOD_COMPONENT_FIELDS}
        fragment ListFoodComponentsResponseFields on ListFoodComponentsResponse {
          data {
          ...FoodComponentFields
        }
        }
      `;
    

      export const COMPONENTS_QUANTITY_FIELDS = gql`
        
        fragment ComponentsQuantityFields on ComponentsQuantity {
          id
  quantity
        }
      `;
    

      export const FOOD_SIZE_FIELDS = gql`
        ${COMPONENTS_QUANTITY_FIELDS}
        fragment FoodSizeFields on FoodSize {
          id
  size
  quantity
  components {
          ...ComponentsQuantityFields
        }
  extraMeals
  componentsCost
  purchasingCost
  packagingCost
        }
      `;
    

      export const PORTIONING_STATUS_FIELDS = gql`
        ${PROCESS_TIME_FIELDS}
        fragment PortioningStatusFields on PortioningStatus {
          session
  readyForAssemblyTime
  assemblingTime {
          ...ProcessTimeFields
        }
  readyForPortioningTime
  portioningTime {
          ...ProcessTimeFields
        }
  endTime
        }
      `;
    

      export const FOOD_FIELDS = gql`
        ${LOCALIZED_FIELDS}
${FOOD_SIZE_FIELDS}
${PORTIONING_STATUS_FIELDS}
        fragment FoodFields on Food {
          id
  tk
  name {
          ...LocalizedFields
        }
  count
  size
  componentIds
  date
  country
  kitchen
  isAssemblyRequired
  sizes {
          ...FoodSizeFields
        }
  isCustom
  type
  tags
  slug
  deliveryTime
  portioningStatus {
          ...PortioningStatusFields
        }
  brand
  portioningURL
  assemblyURL
  componentsCost
        }
      `;
    

      export const LIST_FOODS_RESPONSE_FIELDS = gql`
        ${FOOD_FIELDS}
        fragment ListFoodsResponseFields on ListFoodsResponse {
          data {
          ...FoodFields
        }
        }
      `;
    

      export const PACKAGE_FIELDS = gql`
        
        fragment PackageFields on Package {
          id
  name
  cost
        }
      `;
    

      export const BAGGING_DISPATCH_CITY_FOOD_MODIFICATION_FIELDS = gql`
        
        fragment BaggingDispatchCityFoodModificationFields on BaggingDispatchCityFoodModification {
          id
  modification
  count
        }
      `;
    

      export const BAGGING_DISPATCH_CITY_FIELDS = gql`
        ${BAGGING_DISPATCH_CITY_FOOD_MODIFICATION_FIELDS}
        fragment BaggingDispatchCityFields on BaggingDispatchCity {
          id
  name
  total
  modified
  modifications {
          ...BaggingDispatchCityFoodModificationFields
        }
        }
      `;
    

      export const BAGGING_DISPATCH_FIELDS = gql`
        ${LOCALIZED_FIELDS}
${PACKAGE_FIELDS}
${BAGGING_DISPATCH_CITY_FIELDS}
        fragment BaggingDispatchFields on BaggingDispatch {
          id
  tk
  session
  foodId
  foodName {
          ...LocalizedFields
        }
  foodSize
  foodType
  foodCost
  extra
  leftovers
  purchasingCost
  packagingCost
  isCustomFood
  packages {
          ...PackageFields
        }
  isSandwich
  cities {
          ...BaggingDispatchCityFields
        }
        }
      `;
    

      export const SESSION_INFO_FIELDS = gql`
        ${BAGGING_DISPATCH_FIELDS}
        fragment SessionInfoFields on SessionInfo {
          dispatches {
          ...BaggingDispatchFields
        }
  kitchen
  date
        }
      `;
    

      export const BAGGING_SESSION_FIELDS = gql`
        ${BAGGING_DISPATCH_FIELDS}
        fragment BaggingSessionFields on BaggingSession {
          id
  tk
  date
  kitchen
  startTime
  endTime
  roomTemperature
  session
  dispatches {
          ...BaggingDispatchFields
        }
  status
        }
      `;
    

      export const SESSION_LIST_RESPONSE_FIELDS = gql`
        ${BAGGING_SESSION_FIELDS}
        fragment SessionListResponseFields on SessionListResponse {
          sessions {
          ...BaggingSessionFields
        }
  next
  count
  scannedCount
        }
      `;
    

      export const EXPORT_FILE_RESPONSE_FIELDS = gql`
        
        fragment ExportFileResponseFields on ExportFileResponse {
          data
        }
      `;
    

      export const EXPORT_DOCUMENTS_RESPONSE_FIELDS = gql`
        
        fragment ExportDocumentsResponseFields on ExportDocumentsResponse {
          data
        }
      `;
    

      export const LIST_FOOD_COMPONENT_BY_RANGE_RESPONSE_FIELDS = gql`
        ${FOOD_COMPONENT_FIELDS}
        fragment ListFoodComponentByRangeResponseFields on ListFoodComponentByRangeResponse {
          components {
          ...FoodComponentFields
        }
  next
        }
      `;
    

      export const LIST_RESPONSE_META_FIELDS = gql`
        
        fragment ListResponseMetaFields on ListResponseMeta {
          total
        }
      `;
    

      export const FOOD_ACTION_FIELDS = gql`
        
        fragment FoodActionFields on FoodAction {
          type
  componentId
        }
      `;
    

      export const MEAL_TICKET_FOOD_FIELDS = gql`
        ${LOCALIZED_FIELDS}
${FOOD_ACTION_FIELDS}
        fragment MealTicketFoodFields on MealTicketFood {
          id
  name {
          ...LocalizedFields
        }
  foodType
  size
  positionIndex
  slug
  actions {
          ...FoodActionFields
        }
        }
      `;
    

      export const MEAL_TICKET_DATA_FIELDS = gql`
        ${MEAL_TICKET_FOOD_FIELDS}
        fragment MealTicketDataFields on MealTicketData {
          oldFood {
          ...MealTicketFoodFields
        }
  newFood {
          ...MealTicketFoodFields
        }
        }
      `;
    

      export const ADDRESS_FIELDS = gql`
        
        fragment AddressFields on Address {
          id
  lat
  lng
  country
  name
  region
  city
  district
  street
  building
  type
  apartment
  office
  kitchen
  address
  customDeliveryTime
        }
      `;
    

      export const DRIVER_FIELDS = gql`
        
        fragment DriverFields on Driver {
          id
  name
  code
        }
      `;
    

      export const ADDRESS_TICKET_DATA_FIELDS = gql`
        ${ADDRESS_FIELDS}
${DRIVER_FIELDS}
        fragment AddressTicketDataFields on AddressTicketData {
          oldAddress {
          ...AddressFields
        }
  oldDriver {
          ...DriverFields
        }
  newAddress {
          ...AddressFields
        }
  newDriver {
          ...DriverFields
        }
  newDeliveryTime
        }
      `;
    

      export const MANUAL_TICKET_DATA_FIELDS = gql`
        ${MEAL_TICKET_FOOD_FIELDS}
${ADDRESS_FIELDS}
        fragment ManualTicketDataFields on ManualTicketData {
          foods {
          ...MealTicketFoodFields
        }
  address {
          ...AddressFields
        }
  cutlery
  coolerBag
        }
      `;
    

      export const OTHER_TICKET_DATA_FIELDS = gql`
        
        fragment OtherTicketDataFields on OtherTicketData {
          cutlery
  coolerBag
        }
      `;
    

      export const DELIVERY_TICKET_DATA_FIELDS = gql`
        ${MEAL_TICKET_DATA_FIELDS}
${ADDRESS_TICKET_DATA_FIELDS}
${MANUAL_TICKET_DATA_FIELDS}
${OTHER_TICKET_DATA_FIELDS}
${DRIVER_FIELDS}
        fragment DeliveryTicketDataFields on DeliveryTicketData {
          type
  deliveryId
  deliveryTime
  deliveryShortId
  deliveryOldShortId
  userId
  userName
  phoneNumber
  mealTicketData {
          ...MealTicketDataFields
        }
  addressTicketData {
          ...AddressTicketDataFields
        }
  manualTicketData {
          ...ManualTicketDataFields
        }
  otherTicketData {
          ...OtherTicketDataFields
        }
  comment
  driver {
          ...DriverFields
        }
  autoApprove
        }
      `;
    

      export const DELIVERY_TICKET_FIELDS = gql`
        ${DELIVERY_TICKET_DATA_FIELDS}
${USER_FIELDS}
        fragment DeliveryTicketFields on DeliveryTicket {
          id
  data {
          ...DeliveryTicketDataFields
        }
  date
  createdBy {
          ...UserFields
        }
  resolvedBy {
          ...UserFields
        }
  resolvedAt
  downloadedAt
  kitchen
  zone
  country
  brand
  status
  createdAt
        }
      `;
    

      export const LIST_DELIVERY_TICKETS_RESPONSE_FIELDS = gql`
        ${DELIVERY_TICKET_FIELDS}
        fragment ListDeliveryTicketsResponseFields on ListDeliveryTicketsResponse {
          data {
          ...DeliveryTicketFields
        }
  total
        }
      `;
    

      export const COMPLAINT_USER_FIELDS = gql`
        
        fragment ComplaintUserFields on ComplaintUser {
          userId
  name
  email
  phoneNumber
        }
      `;
    

      export const COMPLAINT_MEAL_FIELDS = gql`
        
        fragment ComplaintMealFields on ComplaintMeal {
          id
  name
  size
  slug
        }
      `;
    

      export const COMPLAINT_COMMENT_FIELDS = gql`
        
        fragment ComplaintCommentFields on ComplaintComment {
          id
  name
  text
        }
      `;
    

      export const COMPLAINT_FIELDS = gql`
        ${USER_FIELDS}
${COMPLAINT_USER_FIELDS}
${COMPLAINT_MEAL_FIELDS}
${COMPLAINT_COMMENT_FIELDS}
${DRIVER_FIELDS}
        fragment ComplaintFields on Complaint {
          id
  category
  type
  date
  kitchen
  resolvedBy {
          ...UserFields
        }
  reviewedBy {
          ...UserFields
        }
  createdBy {
          ...UserFields
        }
  user {
          ...ComplaintUserFields
        }
  resolvedAt
  reviewedAt
  attachment
  attachments
  operationsId
  createdDate
  createdAt
  status
  meal {
          ...ComplaintMealFields
        }
  comments {
          ...ComplaintCommentFields
        }
  deliveryTime
  driver {
          ...DriverFields
        }
  deliveryId
        }
      `;
    

      export const LIST_COMPLAINTS_RESPONSE_FIELDS = gql`
        ${COMPLAINT_FIELDS}
        fragment ListComplaintsResponseFields on ListComplaintsResponse {
          data {
          ...ComplaintFields
        }
  total
        }
      `;
    

      export const ROUT_PLAN_DRIVER_FIELDS = gql`
        
        fragment RoutPlanDriverFields on RoutPlanDriver {
          id
  driverName
  phoneNumber
  email
        }
      `;
    

      export const DRIVER_DISPATCH_FIELDS = gql`
        ${ROUT_PLAN_DRIVER_FIELDS}
        fragment DriverDispatchFields on DriverDispatch {
          id
  driver {
          ...RoutPlanDriverFields
        }
  caloBags
  mealoBags
  vanTemperature
  departureTime
        }
      `;
    

      export const QUALITY_CHECK_RATING_FIELDS = gql`
        
        fragment QualityCheckRatingFields on QualityCheckRating {
          id
  name
  rating
        }
      `;
    

      export const QUALITY_CHECKS_FIELDS = gql`
        ${USER_FIELDS}
${QUALITY_CHECK_RATING_FIELDS}
        fragment QualityChecksFields on QualityChecks {
          id
  createdAt
  comment
  status
  actor {
          ...UserFields
        }
  commentImageURLs
  standards {
          ...QualityCheckRatingFields
        }
        }
      `;
    

      export const COMPONENT_BATCH_FIELDS = gql`
        ${QUALITY_CHECKS_FIELDS}
${USER_FIELDS}
        fragment ComponentBatchFields on ComponentBatch {
          id
  date
  session
  batchNumber
  foodComponentId
  chef
  measurementUnit
  weight
  quality {
          ...QualityChecksFields
        }
  deletedAt
  deletedBy {
          ...UserFields
        }
  brand
  kitchen
  country
        }
      `;
    

      export const LIST_COMPONENT_BATCHES_RESPONSE_FIELDS = gql`
        ${COMPONENT_BATCH_FIELDS}
        fragment ListComponentBatchesResponseFields on ListComponentBatchesResponse {
          data {
          ...ComponentBatchFields
        }
  total
        }
      `;
    

      export const CHEF_FIELDS = gql`
        
        fragment ChefFields on Chef {
          id
  name
  session
  stations
  brand
  kitchen
  country
  deletedAt
        }
      `;
    

      export const LIST_CHEFS_FIELDS = gql`
        ${CHEF_FIELDS}
        fragment ListChefsFields on ListChefs {
          data {
          ...ChefFields
        }
        }
      `;
    

      export const INGREDIENT_FIELDS = gql`
        ${LOCALIZED_FIELDS}
        fragment IngredientFields on Ingredient {
          id
  name {
          ...LocalizedFields
        }
  quantity
  wastage
  kitchen
  date
  brand
  measurementUnit
  internalName
  purchasingCost
  header
  weight
        }
      `;
    

      export const SINGLE_FOOD_COMPONENT_FIELDS = gql`
        ${LOCALIZED_FIELDS}
${COOKING_STATIONS_FIELDS}
${KITCHEN_WASTAGE_INPUTS_FIELDS}
${INGREDIENT_FIELDS}
${FOOD_COMPONENT_METHOD_STEP_FIELDS}
${FOOD_COMPONENT_FIELDS}
${CUP_SIZES_FIELDS}
${CUPS_QUANTITY_FIELDS}
${FOOD_COMPONENT_QUALITY_STANDARD_FIELDS}
        fragment SingleFoodComponentFields on SingleFoodComponent {
          id
  tk
  name {
          ...LocalizedFields
        }
  count
  weight
  cookedWeight
  cookedRawFactor
  measurementUnit
  cookingStations {
          ...CookingStationsFields
        }
  country
  kitchen
  kitchenWastageInputs {
          ...KitchenWastageInputsFields
        }
  brand
  ingredients {
          ...IngredientFields
        }
  method {
          ...FoodComponentMethodStepFields
        }
  childComponents {
          ...FoodComponentFields
        }
  foodsIds
  cost
  quantities
  cups {
          ...CupSizesFields
        }
  cupsQuantity {
          ...CupsQuantityFields
        }
  standards {
          ...FoodComponentQualityStandardFields
        }
        }
      `;
    

      export const DAILY_DISPATCH_LEFTOVERS_STATS_MEAL_TYPES_ITEM_FIELDS = gql`
        
        fragment DailyDispatchLeftoversStatsMealTypesItemFields on DailyDispatchLeftoversStatsMealTypesItem {
          id
  foodCost
  packagingCost
  count
        }
      `;
    

      export const DAILY_DISPATCH_LEFTOVERS_STATS_MEAL_TYPES_FIELDS = gql`
        ${DAILY_DISPATCH_LEFTOVERS_STATS_MEAL_TYPES_ITEM_FIELDS}
        fragment DailyDispatchLeftoversStatsMealTypesFields on DailyDispatchLeftoversStatsMealTypes {
          id
  mealType
  sandwiches {
          ...DailyDispatchLeftoversStatsMealTypesItemFields
        }
  nonSandwiches {
          ...DailyDispatchLeftoversStatsMealTypesItemFields
        }
        }
      `;
    

      export const DAILY_DISPATCH_LEFTOVERS_STATS_FIELDS = gql`
        ${DAILY_DISPATCH_LEFTOVERS_STATS_MEAL_TYPES_FIELDS}
        fragment DailyDispatchLeftoversStatsFields on DailyDispatchLeftoversStats {
          id
  date
  kitchen
  stats {
          ...DailyDispatchLeftoversStatsMealTypesFields
        }
        }
      `;
    

      export const LIST_DAILY_DISPATCH_LEFTOVER_STATS_RESPONSE_FIELDS = gql`
        ${DAILY_DISPATCH_LEFTOVERS_STATS_FIELDS}
        fragment ListDailyDispatchLeftoverStatsResponseFields on ListDailyDispatchLeftoverStatsResponse {
          data {
          ...DailyDispatchLeftoversStatsFields
        }
        }
      `;
    

      export const LIST_BAGGING_DISPATCH_BY_RANGE_RESPONSE_FIELDS = gql`
        ${BAGGING_DISPATCH_FIELDS}
        fragment ListBaggingDispatchByRangeResponseFields on ListBaggingDispatchByRangeResponse {
          dispatches {
          ...BaggingDispatchFields
        }
  next
  count
  scannedCount
        }
      `;
    

      export const MODIFIED_DELIVERY_IDS_FIELDS = gql`
        
        fragment ModifiedDeliveryIdsFields on ModifiedDeliveryIds {
          downloadSequence
  deliveryIds
        }
      `;
    

      export const SHOPPING_MENU_FOOD_FIELDS = gql`
        ${LOCALIZED_FIELDS}
${MODIFIED_DELIVERY_IDS_FIELDS}
        fragment ShoppingMenuFoodFields on ShoppingMenuFood {
          id
  name {
          ...LocalizedFields
        }
  order
  isDone
  isCustom
  type
  size
  modifiedDeliveryIds {
          ...ModifiedDeliveryIdsFields
        }
  portioningURL
        }
      `;
    

      export const SHOPPING_MENU_FIELDS = gql`
        ${SHOPPING_MENU_FOOD_FIELDS}
        fragment ShoppingMenuFields on ShoppingMenu {
          id
  day
  country
  kitchen
  food {
          ...ShoppingMenuFoodFields
        }
        }
      `;
    

      export const SCAN_SHOPPING_DELIVERY_RESPONSE_FIELDS = gql`
        ${SHOPPING_MENU_FOOD_FIELDS}
        fragment ScanShoppingDeliveryResponseFields on ScanShoppingDeliveryResponse {
          id
  food {
          ...ShoppingMenuFoodFields
        }
  name
  day
  kitchen
  brand
        }
      `;
    

      export const DELETE_COMPONENT_BATCH_RESPONSE_FIELDS = gql`
        
        fragment DeleteComponentBatchResponseFields on DeleteComponentBatchResponse {
          message
        }
      `;
    

      export const KDSKITCHEN_FIELDS = gql`
        
        fragment KDSKitchenFields on KDSKitchen {
          id
  sessions
  areas
        }
      `;
    

      export const LIST_KITCHENS_RESPONSE_FIELDS = gql`
        ${KDSKITCHEN_FIELDS}
        fragment ListKitchensResponseFields on ListKitchensResponse {
          data {
          ...KDSKitchenFields
        }
        }
      `;
    

      export const RANGE_FIELDS = gql`
        
        fragment RangeFields on Range {
          gte
  lte
        }
      `;
    

      export const DELIVERY_FOOD_FIELDS = gql`
        ${LOCALIZED_FIELDS}
        fragment DeliveryFoodFields on DeliveryFood {
          id
  name {
          ...LocalizedFields
        }
  size
  tags
  type
  isCustom
  skipped
  isSwapped
        }
      `;
    

      export const DELIVERY_FIELDS = gql`
        ${DELIVERY_FOOD_FIELDS}
${DRIVER_FIELDS}
        fragment DeliveryFields on Delivery {
          id
  userId
  food {
          ...DeliveryFoodFields
        }
  name
  phoneNumber
  kitchen
  day
  status
  skipped
  shortId
  driver {
          ...DriverFields
        }
  brand
  downloadedDocs
  time
  customDeliveryTime
  pickupTime
        }
      `;
    

      export const LIST_DELIVERIES_RESPONSE_FIELDS = gql`
        ${DELIVERY_FIELDS}
        fragment ListDeliveriesResponseFields on ListDeliveriesResponse {
          data {
          ...DeliveryFields
        }
  total
        }
      `;
    

      export const META_FIELDS = gql`
        
        fragment MetaFields on Meta {
          page
  limit
  total
        }
      `;
    

      export const LIST_FOODS_BY_FILTERS_RESPONSE_FIELDS = gql`
        ${FOOD_FIELDS}
${META_FIELDS}
        fragment ListFoodsByFiltersResponseFields on ListFoodsByFiltersResponse {
          data {
          ...FoodFields
        }
  meta {
          ...MetaFields
        }
        }
      `;
    

      export const GIFT_ITEM_DATA_FIELDS = gql`
        ${LOCALIZED_FIELDS}
        fragment GiftItemDataFields on GiftItemData {
          id
  name {
          ...LocalizedFields
        }
  slug
  size
        }
      `;
    

      export const GIFT_ITEM_FIELDS = gql`
        ${GIFT_ITEM_DATA_FIELDS}
        fragment GiftItemFields on GiftItem {
          id
  type
  amount
  item {
          ...GiftItemDataFields
        }
        }
      `;
    

      export const GIFT_FIELDS = gql`
        ${GIFT_ITEM_FIELDS}
${USER_FIELDS}
        fragment GiftFields on Gift {
          id
  kitchen
  userId
  userName
  phoneNumber
  zone
  date
  type
  items {
          ...GiftItemFields
        }
  deliveryId
  deliveryTime
  createdBy {
          ...UserFields
        }
  comment
  attachments
  createdAt
  updatedAt
        }
      `;
    

      export const LIST_GIFTS_RESPONSE_FIELDS = gql`
        ${GIFT_FIELDS}
        fragment ListGiftsResponseFields on ListGiftsResponse {
          data {
          ...GiftFields
        }
  next
        }
      `;
    

      export const QUALITY_METRIC_FOOD_FIELDS = gql`
        ${LOCALIZED_FIELDS}
        fragment QualityMetricFoodFields on QualityMetricFood {
          id
  cacheKey
  name {
          ...LocalizedFields
        }
  componentsIds
        }
      `;
    

      export const QUALITY_METRICS_FOOD_COMPONENT_FIELDS = gql`
        ${LOCALIZED_FIELDS}
        fragment QualityMetricsFoodComponentFields on QualityMetricsFoodComponent {
          id
  cacheKey
  name {
          ...LocalizedFields
        }
  cost
  weight
  lastCookingStation
  consistencyScore
  numberOfRejected
  totalReviewed
  weightOfRejected
  cookedWeight
        }
      `;
    

      export const QUALITY_METRIC_FIELDS = gql`
        ${QUALITY_METRIC_FOOD_FIELDS}
${QUALITY_METRICS_FOOD_COMPONENT_FIELDS}
        fragment QualityMetricFields on QualityMetric {
          id
  day
  kitchen
  shift
  isFinalized
  food {
          ...QualityMetricFoodFields
        }
  components {
          ...QualityMetricsFoodComponentFields
        }
        }
      `;
    

      export const LIST_QUALITY_METRICS_RESPONSE_FIELDS = gql`
        ${QUALITY_METRIC_FIELDS}
        fragment ListQualityMetricsResponseFields on ListQualityMetricsResponse {
          data {
          ...QualityMetricFields
        }
        }
      `;
    

      export const FOOD_METRICS_FIELDS = gql`
        
        fragment FoodMetricsFields on FoodMetrics {
          cacheKey
  shift
  numberOfFoods
  numberOfModified
  portioningTime
  predictedPortioningTimePerMeal
        }
      `;
    

      export const PRODUCTIVITY_METRICS_FOOD_FIELDS = gql`
        ${LOCALIZED_FIELDS}
${FOOD_METRICS_FIELDS}
        fragment ProductivityMetricsFoodFields on ProductivityMetricsFood {
          id
  cacheKey
  name {
          ...LocalizedFields
        }
  numberOfComponents
  metrics {
          ...FoodMetricsFields
        }
  type
  tags
  isSandwich
        }
      `;
    

      export const PRODUCTIVITY_METRICS_FIELDS = gql`
        ${PRODUCTIVITY_METRICS_FOOD_FIELDS}
        fragment ProductivityMetricsFields on ProductivityMetrics {
          id
  day
  kitchen
  foods {
          ...ProductivityMetricsFoodFields
        }
        }
      `;
    

      export const COMPONENT_LEFTOVER_STATS_BY_STATION_FIELDS = gql`
        
        fragment ComponentLeftoverStatsByStationFields on ComponentLeftoverStatsByStation {
          name
  leftovers
  totalLeftoverCost
        }
      `;
    

      export const COMPONENT_LEFTOVER_STATS_FIELDS = gql`
        ${COMPONENT_LEFTOVER_STATS_BY_STATION_FIELDS}
        fragment ComponentLeftoverStatsFields on ComponentLeftoverStats {
          id
  date
  kitchen
  stations {
          ...ComponentLeftoverStatsByStationFields
        }
        }
      `;
    

      export const LIST_FOOD_COMPONENT_LEFTOVER_STATS_RESPONSE_FIELDS = gql`
        ${COMPONENT_LEFTOVER_STATS_FIELDS}
        fragment ListFoodComponentLeftoverStatsResponseFields on ListFoodComponentLeftoverStatsResponse {
          data {
          ...ComponentLeftoverStatsFields
        }
        }
      `;
    

      export const LIST_PRODUCTIVITY_METRICS_RESPONSE_FIELDS = gql`
        ${PRODUCTIVITY_METRICS_FIELDS}
        fragment ListProductivityMetricsResponseFields on ListProductivityMetricsResponse {
          data {
          ...ProductivityMetricsFields
        }
        }
      `;
    
  