import { Stack } from '@mui/material';
import { round } from 'lodash-es';
import SummaryBox from '../../Common/SummaryBox';
import { LeftoverStats } from '../ComponentsView';

interface TopSectionProps {
	leftoverStats: LeftoverStats;
}

const TopSection = ({ leftoverStats }: TopSectionProps) => {
	return (
		<Stack sx={{ width: '100%' }} direction={'row'} spacing={'10px'} marginBottom={'35px'}>
			<SummaryBox topText="AMOUNT OF LEFTOVERS" bottomLeftText={round(leftoverStats.leftovers ?? 0, 2).toString() ?? '-'} bottomRightText="g" />
			<SummaryBox
				topText="TOTAL COST OF LEFTOVERS"
				bottomLeftText={`${leftoverStats.currency ?? 'BHD'} ${round(leftoverStats.cost ?? 0, 2) ?? '-'}`}
			/>
		</Stack>
	);
};

export default TopSection;
